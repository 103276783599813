/* CSS Document */

/* 
*
*
[Table of contents]
*
*
*

  color / $white
  font / @import 
  general / html
  dropdown / .dropdown-custom.btn
  navbar / .navbar
  slider home / .jumbomain
  breadcumb / .breadcumb
  home / .subheading
  slickteam / .slickteam
  pricing / .pricelist
  testimony / .wraper-testimony
  footer / footer
  portofolio / AppContainer
  contact / .form-side
  service / #service
  blog / #blog
  media all / @media only screen and (max-width: 1199px)

*/

/*color*/
$white: #fff;
$gray: #bbb;
$black: #111;
$black_soft: #272727;
$black_col: #2e333c;
$black_more: #0f0f0f;
$color: #c8b16f;
$color_more: #c7ab5a;
$toolbar1: #4577ab;
$toolbar2: #816b66;

/*font*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

/*general*/
html {
  scroll-behavior: auto;
}
body {
  font-family: "Poppins", "Segoe UI", "Ubuntu", "Droid Sans", sans-serif;
  font-weight: 300;
  font-size: 15px;
  letter-spacing: 0.3px;
  color: $white;
  background: linear-gradient(to left, #494f5f, #6e5d5c);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#mainpreloader {
  .preloader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to left, $toolbar1, $toolbar2);
    z-index: 9999999;
    -webkit-animation-duration: 1.6s;
    animation-duration: 1.6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    25% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    25% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
  }
}
.mainpreloader {
  position: relative;
  animation: rotate 1s infinite linear;
  border: 3px solid rgba(255, 255, 255, 0.1);
  width: 40px;
  height: 40px;
  margin-right: -24px;
  border-radius: 999px;
}
.mainpreloader span {
  position: absolute;
  width: 40px;
  height: 40px;
  border: 3px solid transparent;
  border-top: 3px solid #c8b16f;
  top: -3px;
  left: -3px;
  border-radius: 999px;
}
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#routerhang {
  transform: unset !important;
  transform-origin: unset !important;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.br {
  display: block;
  white-space: pre-wrap;
}
section {
  padding: 75px 0;
}

.wraper {
  padding: 0;
  display: block;
  overflow: hidden;
  width: 100%;
  .wraperitem {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
.m-2-hor {
  padding: 0 7%;
  max-width: 1500px;
  margin: 0 auto !important;
}
.m-10-hor {
  display: flex;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;
  max-width: 1500px;
  margin: 0 auto;
}
.white {
  color: $black;
  background: $white;
}
.themegradient {
  background: linear-gradient(to left, #494f5f, #6e5d5c);
}
.black_more {
  background: $black_more;
}
.color {
  color: $color;
}
.btn {
  position: relative;
  overflow: hidden;
  font-size: 11pt;
  color: $white;
  background: none;
  border: 2px solid $color;
  border-radius: 0;
  padding: 10px 44px;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  z-index: 0;
  span {
    color: $white;
    position: relative;
    z-index: 1;
  }
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background: linear-gradient(to left, #494f5f, #6e5d5c);
    opacity: 0;
    z-index: 1;
    transition: all 0.3s ease;
  }
  &:hover::before {
    opacity: 1;
    transition: all 0.3s ease;
  }
  &:hover {
    .shine {
      -webkit-animation: sheen 0.6s alternate;
      animation: sheen 0.6s alternate;
    }
  }
}
.btnround {
  margin-top: 10px;
  position: relative;
  overflow: hidden;
  font-size: 11pt;
  color: $white;
  background: none;
  border: 2px solid wheat;

  border-radius: 0;
  padding: 10px 44px;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  z-index: 0;
  span {
    color: $white;
    position: relative;
    z-index: 1;
  }
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background: linear-gradient(to left, #494f5f, #6e5d5c);
    opacity: 0;
    z-index: 1;
    transition: all 0.3s ease;
  }
  &:hover::before {
    opacity: 1;
    transition: all 0.3s ease;
  }
  &:hover {
    .shine {
      -webkit-animation: sheen 0.6s alternate;
      animation: sheen 0.6s alternate;
    }
  }
}
.shine {
  content: "";
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: -50px;
  background: -webkit-linear-gradient(
    top,
    transparent,
    rgba(255, 255, 255, 0.2) 5%,
    transparent
  );
  background: linear-gradient(
    to bottom,
    transparent,
    rgba(255, 255, 255, 0.2) 5%,
    transparent
  );
  -webkit-transform: rotateZ(60deg) translate(-1em, 5.5em);
  transform: rotateZ(60deg) translate(-1em, 5.5em);
}
@-webkit-keyframes sheen {
  0% {
    opacity: 1;
    -webkit-transform: rotateZ(60deg) translate(0, 6em);
    transform: rotateZ(60deg) translate(0, 6em);
  }
  90% {
    opacity: 1;
    -webkit-transform: rotateZ(60deg) translate(0, -12em);
    transform: rotateZ(60deg) translate(0, -12em);
  }
  100% {
    opacity: 0;
    -webkit-transform: rotateZ(60deg) translate(0, -12em);
    transform: rotateZ(60deg) translate(0, -12em);
  }
}

@keyframes sheen {
  0% {
    opacity: 1;
    -webkit-transform: rotateZ(60deg) translate(0, 6em);
    transform: rotateZ(60deg) translate(0, 6em);
  }
  90% {
    opacity: 1;
    -webkit-transform: rotateZ(60deg) translate(0, -12em);
    transform: rotateZ(60deg) translate(0, -12em);
  }
  100% {
    opacity: 0;
    -webkit-transform: rotateZ(60deg) translate(0, -12em);
    transform: rotateZ(60deg) translate(0, -12em);
  }
}
.text-gradient {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  background: linear-gradient(to bottom, $color, $color_more);
}
/* dropdown */
.dropdown-custom.btn {
  border: 0px;
  position: relative;
  top: -1px;
  overflow: unset !important;
  font-weight: 500;
  padding: 0 20px;
  background: none !important;
  &::before {
    content: "";
    position: absolute;
    top: -10px;
    left: 19px;
    width: max-content;
    height: auto;
    padding: 30px 40px;
    background: 0;
  }
  &::after {
    margin-left: 10px !important;
  }
  &:focus {
    box-shadow: none !important;
  }
  &:hover {
    color: $black;
  }
}
.dropdown-toggle::after {
  position: relative;
  top: 1px;
}
.item-dropdown {
  width: max-content;
  padding: 0;
  position: absolute;
  background: #1b1b1b;
  border-radius: 0px;
  inset: 40px auto auto 19px;
  padding: 5px 0;
  animation: smoothDrop 0.2s ease;
  -webkit-animation: smoothDrop 0.2s ease;
  z-index: 1;
  .dropdown {
    position: relative;
    text-align: center;
    a {
      color: rgba(255, 255, 255, 0.8);
      text-transform: none;
      font-weight: 400;
      letter-spacing: 0.6px;
      display: block;
      font-size: 0.78rem !important;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding: 12px 20px 12px 20px;
      min-width: 210px;
      width: 100%;
      text-align: left;
      &:hover {
        color: $white;
        background: 0;
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
.imgslickz {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}
/*navbar*/
.navbar {
  padding: 20px 0;
  position: absolute;
  top: 15px;
  left: 0;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0);
  z-index: 999;
  .w-100-nav {
    margin: 0;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  a {
    position: relative;
    font-size: 0.85rem;
    font-weight: 500;
    letter-spacing: 0.6px;
    padding: 4px 20px;
    text-decoration: none !important;
    color: $white;
    outline: none;
    transition: all 0.3s ease;
    &:hover {
      color: $black;
      transition: all 0.3s ease;
    }
    &.active {
      color: $black !important;
      transition: all 0.3s ease;
      &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        background: 0;
        cursor: default;
      }
    }
  }
  .navbar-title {
    margin-right: auto;
    font-size: 150%;
    padding: 12px 16px;
  }
  .navbar-item {
    position: relative;
    display: inline-block;
    padding: 0 5px;
    cursor: default;
    vertical-align: middle;
  }

  .logo {
    .navbar-item {
      position: relative;
      top: 0px;
      left: 0px;
      padding: 0;
      width: 70px;
      border-bottom: none;
      zoom: 250%;
      a {
        padding: 0;
      }
    }
  }
  .nav-icon {
    display: none;
  }
  @media only screen and (max-width: 1199px) {
    .m-2-hor {
      padding: 0px 2%;
    }
    .menu {
      padding: 15px 0 10px;
    }
    &.navbar {
      background: linear-gradient(to left, #3b6faa, #5a91c1);
      position: relative;
    }
    .w-100-nav {
      display: block;
    }
    .navbar-item {
      display: block;
      padding: 15px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
    .nav-icon {
      position: absolute;
      top: 25px;
      right: 25px;
      display: block;
      width: 20px;
      height: 10px;
      padding-top: 13px;
      line-height: 0;
      cursor: pointer;
      background: none;
      border: none;
      padding: 0;
      z-index: 9999;
      zoom: 200%;
    }
    .bg-nav-icon {
      width: 32px;
      height: 24px;
      background: rgba(255, 255, 255, 0);
      border-radius: 2px;
      -moz-border-radius: 2px;
      -webkit-border-radius: 2px;
    }
    #nav-icon span,
    .nav-icon span {
      display: none;
      position: absolute;
      top: 5px;
      left: -40px;
      font-size: 0.62rem;
      font-weight: 600;
      text-transform: uppercase;
    }
    .menu-line {
      position: absolute;
      width: 100%;
      height: 2px;
      top: 0px;
      background-color: #fff;
    }
    .menu-line1 {
      position: absolute;
      width: 100%;
      height: 2px;
      top: 5px;
      background-color: #fff;
    }
    .menu-line2 {
      position: absolute;
      width: 100%;
      height: 2px;
      top: 10px;
      background-color: #fff;
    }
    &.sticky {
      position: absolute !important;
    }
  }
  &.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    background: linear-gradient(to left, $toolbar1, $toolbar2);
    animation: smoothScroll 0.6s forwards;
    -webkit-animation: smoothScroll 0.6s forwards;
  }
  @keyframes smoothScroll {
    0% {
      transform: translateY(-300px);
      -webkit-transform: translateY(-300px);
    }
    100% {
      transform: translateY(0px);
      -webkit-transform: translateY(0px);
    }
  }
  @keyframes smoothDrop {
    0% {
      opacity: 0;
      transform: translateY(10px);
      -webkit-transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
      -webkit-transform: translateY(0px);
    }
  }
}
/* disable click active */
.navbar-item {
  pointer-events: auto;
}
.navbar-item a.active {
  pointer-events: none;
}
/* disable click active */

/* slider */
.jumbomain {
  position: relative;
  padding: 0;
  margin: 0;
}
.slider-wrapper {
  position: relative;
  height: 100vh;
  overflow: hidden;
  .previousButton,
  .nextButton {
    opacity: 0;
    text-decoration: none;
    transition: all 0.3s ease;
  }
  &:hover {
    .previousButton,
    .nextButton {
      opacity: 1;
      transition: all 0.3s ease;
    }
  }
}
.slide {
  background-size: cover !important;
}
.slide::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  bottom: 0;
  left: 0;
}
.previousButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  text-align: center;
  svg {
    display: none;
  }
}
.previousButton::before,
.nextButton::before {
  content: "\f054";
  font-family: FontAwesome;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: normal;
  color: #fff;
  opacity: 0.8;
}
.previousButton:hover,
.nextButton:hover {
}
.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
  transform: rotate(180deg) translateY(calc(50% + 0px));
}
.previousButton {
  left: 4%;
}
.nextButton {
  right: 4%;
}
.slider-content {
  text-align: left;
}
.slider-content .inner {
  padding: 0 12%;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.slider-content .inner button {
  font-weight: 500;
  position: relative;
  overflow: hidden;
  font-size: 11pt;
  letter-spacing: 1px;
  color: $white;
  border: 2px solid $color;
  background: none;
  border-radius: 0;
  padding: 0 44px;
  height: 45px;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  z-index: 0;
  span {
    position: relative;
    z-index: 1;
  }
  &::before {
    content: "";
    width: 180%;
    height: 180%;
    top: -100%;
    left: -100%;
    position: absolute;
    background-image: linear-gradient(to left, $color, $color_more);
    opacity: 0;
    z-index: 1;
    transition: all 0.3s ease;
  }
  &:hover::before {
    top: 0;
    left: 0;
    opacity: 1;
    transition: all 0.3s ease;
  }
  &:hover {
    .shine {
      -webkit-animation: sheen 0.6s alternate;
      animation: sheen 0.6s alternate;
    }
  }
}
.slider-content .inner h1 {
  font-weight: 600;
  max-width: 840px;
  color: #fff;
  font-size: 53px;
}
.slider-content .inner p {
  color: #fff;
  font-size: 40px;
  line-height: 1.3;
  max-width: 640px;
  margin-bottom: 30px;
}

.slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
}
.slider-content section span {
  color: #fff;
}
.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}
.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}
.slider-content section span strong {
  color: #fff;
  font-size: 14px;
  display: block;
}
@media (max-height: 500px) {
  .slider-wrapper,
  .slide {
    height: calc(100vh - 75px);
  }
}
@media (max-width: 640px) {
  .slider-wrapper,
  .slide {
    height: calc(80vh - 75px);
  }
}
@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}
@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
  .slider-content .inner p {
    font-size: 21px;
  }
}
.slide h1 {
  transition: all 0.3s ease;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  opacity: 0;
}
.slide button {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}
.slide p {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}
.slide section * {
  transition: all 0.3s ease;
}
.slide section img {
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
  opacity: 0;
}
.slide section span {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  opacity: 0;
}
.slide section span strong {
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  opacity: 0;
}
.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
  opacity: 1;
}
.slide.animateIn.previous p,
.slide.animateIn.next p {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}
.slide.animateIn.previous button,
.slide.animateIn.next button {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}
.slide.animateIn.previous section img,
.slide.animateIn.next section img {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
  -webkit-transition-delay: 1.4s;
  transition-delay: 1.4s;
}
.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s;
}
.slide.animateOut h1 {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.slide.animateOut p {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.slide.animateOut section span {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.slide.animateOut section span strong {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
/* breadcumb */
.breadcumb {
  border-radius: 0;
  margin: 0;
  padding: 0 0;
  background-size: cover;
  width: 100%;
  position: relative;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to left, #494f5f, #6e5d5c);
    opacity: 0.5;
  }
  .mainbreadcumb {
    padding: 320px 0 30px;
    h1 {
      margin-bottom: 0;
      line-height: 1;
    }
    .list {
      position: relative;
      bottom: -20px;
      display: flex;
      justify-content: right;
      font-size: 0.86rem;
      font-weight: 400;
      letter-spacing: 4px;
      .dash {
        padding: 0 10px;
      }
      .link {
        color: $white;
        font-weight: 500;
        line-height: 1.3;
        letter-spacing: 1px;
        margin: 0;
        padding: 0;
        text-decoration: none;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
          color: $color;
          transition: all 0.3s ease;
        }
      }
    }
  }
  .homebread {
    width: 100%;
    padding: 180px 0 140px;
    .heading {
      line-height: 1.3;
      font-size: 3rem;
      font-weight: 700;
      margin-bottom: 15px;
    }
    .subheading {
      font-size: 2.1rem;
      margin-bottom: 0;
    }
    .content {
      max-width: 500px;

      margin-bottom: 0;
      margin-bottom: 30px;
    }
  }
  &.h-vh {
    height: 100vh;
    display: flex;
    align-items: center;
  }
}
/* home */
.subheading {
  font-size: 1.3rem;
  margin-bottom: 15px;
}
.heading {
  font-size: 2.3rem;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 15px;
  letter-spacing: 1px;
}
.content {
  margin-bottom: 0.5rem;
}
.col-feature {
  display: block;
  .sub-color {
    color: $color;
    font-size: 1.6rem;
    font-weight: 700;
  }
  .heading {
    font-size: 1.1rem;
    font-weight: 500;
    margin: 5px 0;
  }
  .content {
  }
}
.border-radius {
  border-radius: 0px;
  box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: all 0.3s ease;
  &:hover {
    box-shadow: none;
    transition: all 0.3s ease;
  }
}
/* icon scroll */
.icon-scroll-wraper {
  position: absolute;
  width: 100%;
  bottom: 5vh;
  left: 0;
}
.icon-scroll {
  position: relative;
  margin: 0 auto;
}
.icon-scroll:before {
  position: absolute;
}
.icon-scroll {
  width: 26px;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.3);
  z-index: 1;
  border-radius: 25px;
  height: 38px;
}
.icon-scroll:before {
  content: "";
  width: 3px;
  height: 6px;
  background: rgba(255, 255, 255, 0.6);
  left: 12px;
  top: 8px;
  border-radius: 4px;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: scroll;
  animation-name: scroll;
}
@-webkit-keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}
@keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}
.features {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 60px;
  overflow: hidden;
  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .content {
    position: relative;
    text-align: center;
    .heading {
      color: $color;
      font-weight: 500;
      font-size: 1.2rem;
      margin-bottom: 10px;
    }
    .con-text {
      font-size: 0.9rem;
    }
    .link {
      width: max-content;
      font-weight: 500;
      position: relative;
      overflow: hidden;
      font-size: 11pt;
      letter-spacing: 1px;
      color: #fff;
      border: 2px solid $color;
      background: none;
      border-radius: 0;
      padding: 0 44px;
      height: 45px;
      line-height: 40px;
      margin: 20px auto 0;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        background: $color;
        border: 2px solid rgba(0, 0, 0, 0);
        transition: all 0.3s ease;
        .shine {
          -webkit-animation: sheen 0.6s alternate;
          animation: sheen 0.6s alternate;
        }
      }
    }
  }
}
/*slickteam*/
.slickteam {
  width: 100%;
  position: relative;
  padding: 30px 0 0;
  .slick-prev {
    opacity: 0;
    left: -30px;
    z-index: 1;
    transition: all 0.3s ease;
    &::before {
      content: "\f053" !important;
      font-family: FontAwesome !important;
      font-style: normal;
      font-weight: normal;
      color: #fff;
    }
  }
  .slick-next {
    right: -30px;
    opacity: 0;
    transition: all 0.3s ease;
    &::before {
      content: "\f054" !important;
      font-family: FontAwesome !important;
      font-style: normal;
      font-weight: normal;
      color: #fff;
    }
  }
  .slick-slide {
    position: relative;
    div {
      margin: 0 5px;
      .itm {
        margin: 0;
        position: relative;
        height: 100%;
        outline: none;
        overflow: hidden;
        .bg {
          position: relative;
          overflow: hidden;
          border-radius: 4px;
          img {
            width: 100%;
            height: 100%;
            min-height: 380px;
            object-fit: cover;
          }
          &:before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: linear-gradient(to top, #000, rgba(0, 0, 0, 0.3));
            opacity: 0.5;
            transition: all 0.3s ease;
          }
        }
        .desc {
          position: absolute;
          bottom: 15px;
          left: 15px;
          text-align: left;
          .name {
            font-weight: 500;
            font-size: 1.3rem;
            letter-spacing: 1px;
          }
          .title {
            font-weight: 400;
            font-size: 0.9rem;
            line-height: 1.5;
            transition: all 0.3s ease;
          }
        }
        .icon {
          width: 100%;
          position: absolute;
          text-align: right;
          right: 15px;
          bottom: -30px;
          transition: all 0.3s ease;
          span {
            width: 100%;
            height: auto;
            color: inherit;
            padding: 10px;
            i {
              transition: all 0.3s ease;
            }
            &:hover {
              i {
                transform: scale(1.3);
              }
            }
          }
        }
        &:hover {
          cursor: pointer;
          .bg:before {
            opacity: 0.8;
            background: linear-gradient(to top, #000, rgba(0, 0, 0, 0.3));
            transition: all 0.3s ease;
          }
          .icon {
            bottom: 15px;
            transition: all 0.3s ease;
          }
          .title {
            line-height: 0.9;
            transition: all 0.3s ease;
          }
        }
      }
    }
    &.slick-col {
      display: block;
      overflow: hidden;
      div .itm .bg img {
        min-height: 300px !important;
      }
    }
  }
  &:hover {
    .slick-prev,
    .slick-next {
      opacity: 1;
      transition: all 0.3s ease;
    }
  }
}
/* pricing */
.pricelist {
  display: block;
  border: 8px solid rgba(255, 255, 255, 0.1);
  padding: 20px 10px;
  margin: 30px auto 20px;
  text-align: center;
  transition: all 0.3s ease;
  .heading {
    font-size: 1.75rem;
    font-weight: 500;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 15px 0 30px;
  }
  .bigprice {
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 1;
    padding: 10px 0 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    small {
      font-size: 0.8rem;
      font-weight: 400;
      opacity: 0.8;
    }
  }
  .list {
    list-style: none;
    padding: 0px;
    text-align: left;
    li {
      padding: 10px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      i {
        position: relative;
        top: -2px;
        font-size: 0.8rem;
        padding-right: 2px;
      }
    }
  }
  &:hover {
    border: 8px solid $color;
    transition: all 0.3s ease;
  }
}

/*testimony*/
.wraper-testimony {
  background-size: cover;
  overflow: hidden;
  width: 100%;
  position: relative;
  padding: 110px 0;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to left, $color, $color_more);
    opacity: 0.95;
  }
}
.testimony {
  width: 100%;
  .slick-prev {
    opacity: 0;
    left: -30px;
    z-index: 1;
    transition: all 0.3s ease;
    &::before {
      content: "\f053" !important;
      font-family: FontAwesome !important;
      font-style: normal;
      font-weight: normal;
      color: #fff;
    }
  }
  .slick-next {
    right: -30px;
    opacity: 0;
    transition: all 0.3s ease;
    &::before {
      content: "\f054" !important;
      font-family: FontAwesome !important;
      font-style: normal;
      font-weight: normal;
      color: #fff;
    }
  }
  .slick-slide {
    position: relative;
    div {
      margin: 0 5px;
    }
  }
  &:hover {
    .slick-prev,
    .slick-next {
      opacity: 1;
      transition: all 0.3s ease;
    }
  }
  .blockquote {
    font-size: 0.98rem;
    text-align: left;
    margin: 15px 30px 30px;
    display: flex;
    .fa {
      font-size: 1.3rem;
      display: inline-block;
      margin: 5px 15px 0 0;
      color: $white;
      height: max-content;
    }
    p {
      line-height: 1.8;
      display: inline-block;
    }
    .bytesti {
      font-size: 0.9rem;
      font-weight: 400;
    }
  }
  .slick-dots {
    bottom: -40px;
    li.slick-active button::before {
      opacity: 0.75;
      color: $white;
    }
    li button::before {
      color: $white;
    }
  }
}
/* footer */
footer {
  position: relative;
  padding: 75px 0 0;
  padding-right: 0px !important;
  padding-left: 0px !important;
  overflow: hidden;
  .bg-footer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.3;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .footer-col {
    padding-right: 15px;
    padding-left: 15px;
    margin: 15px 0;
    .heading {
      font-weight: 500;
      font-size: 1.1rem;
      margin-bottom: 10px;
    }
    .content {
      line-height: 1.6;
      letter-spacing: 0.6px;
      margin-bottom: 10px;
      .link {
        font-size: 0.96rem;
        width: max-content;
        letter-spacing: 0;
        padding: 0 0 5px;
        margin-bottom: 5px;
        cursor: pointer;
        transition: all 0.3s ease;
        border-bottom: 1px solid rgba(200, 177, 111, 0.8);
        &:hover {
          opacity: 0.6;
          transition: all 0.3s ease;
        }
      }
      .socialicon {
        position: relative;
        overflow: hidden;
        display: inline-block;
        padding: 10px 14px 6px;
        background: $black;
        margin-right: 10px;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
        border: 1px solid rgba(200, 177, 111, 0.8);
        transition: all 0.3s ease;
        i {
          font-size: 1.3rem;
        }
        &:hover {
          background: $color;
          transition: all 0.3s ease;
          .shine {
            z-index: 1;
            -webkit-animation: sheen 2s alternate;
            animation: sheen 2s alternate;
          }
        }
      }
    }
    .link-call {
      font-weight: 300;
      display: inline-block;
      color: $color;
      font-size: 0.8rem;
      width: max-content;
      letter-spacing: 1px;
      padding: 0 0 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        opacity: 0.6;
        transition: all 0.3s ease;
      }
    }
  }
}
.subfooter {
  width: 100%;
  margin-top: 90px;
  padding: 30px 15px;
  background: rgba(0, 0, 0, 0.6);
  .content {
    font-size: 0.8rem;
    opacity: 1;
    letter-spacing: 0.3px;
    margin: 0;
    color: #eee;
  }
  .content-right {
    text-align: right;
    font-size: 0.9rem;
    opacity: 0.8;
    letter-spacing: 0.3px;
    margin: 0;
    i {
      padding: 0 5px;
    }
  }
  @media only screen and (max-width: 768px) {
    .content-right {
      text-align: left;
      margin-top: 5px;
    }
  }
}
#scroll-to-top {
  cursor: pointer;
  width: max-content;
  height: max-content;
  position: fixed;
  right: 10px;
  z-index: 999;
  &.init {
    bottom: -60px;
    transition: all 0.3s ease;
  }
  &.show {
    bottom: 15px;
    transition: all 0.3s ease;
  }
  div {
    font-size: 0.8rem;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 30px;
    border-radius: 30px;
    background: $color;
    transition: all 0.3s ease;
    &:hover {
      background: #1b1b1b;
      transition: all 0.3s ease;
    }
  }
}

/** portofolio **/
.AppContainer {
  color: $white;
  position: relative;
  z-index: 99;
  height: 100%;
  width: 100%;
  background: none;
  .gallery-container {
    .react-photo-gallery--gallery {
    }
  }
}
.lightbox-portal {
  position: relative;
  z-index: 999;
  h4 {
    font-weight: 700;
  }
}
.btnLright {
  color: $white;
  font-size: 21px !important;
  right: 10px !important;
}
.btnLleft {
  color: $white;
  font-size: 21px !important;
  left: 10px !important;
}
.closeL {
  font-size: 21px !important;
  position: absolute;
  top: 10px;
  right: 10px;
}
.MainGimg {
  transform: scale(1);
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  .overlayCap {
    font-size: 1.3rem;
    width: 100%;
    bottom: 15px;
    padding-left: 20px;
    font-weight: 500;
    left: 0;
    z-index: 1;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(transparent),
      to(rgba(0, 0, 0, 0.5))
    );
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  &:hover {
    transform: scale(1.01);
    box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 2;
  }
}
/** contact **/
.form-side {
  padding: 30px 40px;
  background: $black;
  border-radius: 4px;
}
.text-side {
  padding: 15px 0;
  margin-top: 15px;
  .address {
    padding-top: 15px;
    line-height: 1.6;
    .heading {
      font-size: 1.4rem;
      font-weight: 500;
      letter-spacing: 1px;
    }
    .list {
      position: relative;
      max-width: 320px;
      display: block;
      padding: 5px 0 15px;
      padding-left: 40px;
      i {
        position: absolute;
        top: 5px;
        left: 5px;
        font-size: 1.3rem;
        display: inline-block;
      }
      a {
        color: $white;
        text-decoration: none;
        transition: all 0.3s ease;
        &:hover {
          color: $color;
          transition: all 0.3s ease;
        }
      }
    }
  }
}
.formcontact {
  display: block;
  width: 100%;
  label {
    display: block;
    width: 100%;
    margin-bottom: 5px;
  }
  input {
    width: 100%;
    font-size: 0.9rem;
    width: 100%;
    padding: 8px 10px;
    border: 1px solid #ebebeb;
    margin-bottom: 15px;
  }
  textarea {
    font-size: 0.9rem;
    width: 100%;
    padding: 8px 10px;
    border: 1px solid #ebebeb;
    margin-bottom: 15px;
  }
  .msg-error {
    font-size: 0.87rem;
    opacity: 0.8;
    padding: 5px 0;
  }
  button {
    position: relative;
    overflow: hidden;
    font-size: 11pt;
    color: $white;
    background: none;
    border: 2px solid $color;
    border-radius: 0;
    padding: 8px 50px;
    margin-top: 15px;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    z-index: 0;
    span {
      color: $white;
      position: relative;
      z-index: 3;
    }
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      background-image: linear-gradient(to left, $color, $color_more);
      opacity: 0;
      z-index: 1;
      transition: all 0.3s ease;
    }
    &:hover::before {
      opacity: 1;
      transition: all 0.3s ease;
    }
    &:hover {
      .shine {
        -webkit-animation: sheen 1s alternate;
        animation: sheen 1s alternate;
      }
    }
  }
}
#success,
#failed {
  &.show {
    display: block !important;
  }
  &.hide {
    display: none;
  }
}
#buttonsent.show {
  pointer-events: none;
}
#service,
#blog {
  display: block;
  position: relative;
  .left {
    display: block;
    position: relative;
    .mainimg {
      width: 100%;
      height: auto;
      position: relative;
      margin-bottom: 15px;
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
    .content {
      .title {
        font-size: 1.7rem;
        font-weight: 500;
        letter-spacing: 0.6px;
        text-transform: uppercase;
        padding: 15px 0;
      }
      .desc {
        letter-spacing: 0.3px;
        blockquote {
          font-size: 95%;
          padding: 25px;
          margin: 1.8rem 0 1.5rem;
          font-style: italic;
          background: #333;
          border-left: 4px solid $color;
        }
      }
    }
  }
  .right {
    .services-list {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        position: relative;
        a {
          font-weight: 500;
          font-size: 14px;
          letter-spacing: 0.6px;
          display: block;
          padding: 14px 20px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          background: #333;
          transition: all 0.3s ease;
          cursor: pointer;
          color: $white;
          text-decoration: none;
          &:hover {
            background: #555;
            transition: all 0.3s ease;
          }
          &.active {
            background: #555;
          }
        }
      }
    }
  }
  .bannerservice {
    position: relative;
    padding: 50px 20px;
    margin-top: 30px;
    text-align: center;
    .imgbg {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .contb {
      position: relative;
      z-index: 2;
      .headingb {
        font-size: 1.7rem;
        font-weight: 500;
      }
      .descb {
        font-size: 1rem;
      }
      .btn {
        padding: 10px 20px;
        text-align: center;
        margin-top: 20px;
      }
    }
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.3);
      z-index: 1;
    }
  }
}
#blog {
  .recent-post {
    list-style: none;
    color: $white;
    padding: 0;
    margin: 0 0 45px;
    a.recent {
      color: $white;
      text-decoration: none;
      display: flex;
      margin-bottom: 15px;
      .img {
        width: 75px;
        height: 75px;
        overflow: hidden;
        margin-right: 15px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .contblog {
        width: 70%;
        .title {
          font-size: 1rem;
          font-weight: 500;
        }
        .descblog {
          font-size: 14px;
        }
      }
    }
  }
  h3 {
    width: max-content;
    font-size: 1.6rem;
    margin-bottom: 30px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(200, 177, 111, 0.6);
  }
  .tags {
    list-style: none;
    padding: 0;
    margin-bottom: 0 0 30px;
    li {
      display: inline-block;
      margin-right: 15px;
      margin-bottom: 25px;
      a.tag {
        font-size: 13px;
        letter-spacing: 0.6px;
        text-decoration: none;
        padding: 3px 12px;
        color: $white;
        border: 1px solid rgba(255, 255, 255, 0.3);
        transition: all 0.3s ease;
        &:hover {
          border: 1px solid rgba(200, 177, 111, 0.69);
          transition: all 0.3s ease;
        }
      }
    }
  }
}
/*** media all ***/
@media only screen and (max-width: 1199px) {
  .item-dropdown {
    position: relative;
    width: max-content;
    inset: unset;
    padding-top: 10px;
    border: 0;
    background: none;
    .dropdown {
      a {
        padding: 10px 10px 10px 10px;
        width: max-content;
        text-align: left;
        left: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }
    }
  }
  .navbar {
    top: 0;
  }
}
@media only screen and (max-width: 1024px) {
  .m-10-hor {
    padding-left: 2%;
    padding-right: 2%;
  }
}
@media only screen and (max-width: 768px) {
  .breadcumb .mainbreadcumb .list {
    justify-content: left;
    text-align: left;
    margin-bottom: 30px;
  }
  .previousButton,
  .nextButton {
    opacity: 1 !important;
  }
}
@media only screen and (max-width: 600px) {
}
@media only screen and (max-width: 420px) {
}
@media only screen and (max-width: 378px) {
}
